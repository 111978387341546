import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { makeSelectLocale } from 'containers/LanguageProvider/selectors';

export function LocalizedRouter({
  children,
  RouterComponent,
  locale,
  ...props
}) {
  return (
    <RouterComponent {...props}>
      <Route path="/:lang([it|en])">
        {({ location }) => {
          /**
           * If language is not in route path, redirect to language root
           */
          const { pathname, search, state } = location;
          if (!pathname.includes(`/${locale}/`)) {
            const newPathName = pathname.match(/^\/(it|en)\//i)
              ? pathname.replace(/^\/(it|en)/i, '')
              : pathname;

            const splittedSearch = search ? search.split('?') : [];
            splittedSearch.shift(); // avoid duplicated ? in query string;

            let newSearch = '';
            if (splittedSearch.length) {
              newSearch = `?${splittedSearch.join('&')}`;
            }

            return (
              <Redirect
                to={{
                  pathname: `/${locale}${
                    newPathName.match(/^\/(it|en)$/i) ? '/' : newPathName
                  }`,
                  search: newSearch,
                  state,
                }}
              />
            );
          }

          return children;
        }}
      </Route>
    </RouterComponent>
  );
}

LocalizedRouter.propTypes = {
  RouterComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  locale: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(LocalizedRouter);
