/**
 *
 * ApplyLeadPreview
 *
 */

import { Grid } from '@mui/material';
import { Typography } from '@mamacrowd/ui-kit';

import IndustryText from 'containers/IndustryText';
import FormattedMessage from 'components/FormattedMessage';

import { renderEuroFloat } from 'utils/renderEuroValue';

import {
  ApplyCampaignType,
  ApplyLead,
  isApplicationAnswersLeadEquityV2,
  isApplicationAnswersLeadRealEstateV2,
} from 'types/Application';

import messages from './messages';

export interface ApplyLeadPreviewProps {
  lead?: ApplyLead;
  applyCampaignType: `${ApplyCampaignType}`;
}

export function ApplyLeadPreview({
  lead,
  applyCampaignType,
}: ApplyLeadPreviewProps) {
  const isEquityCompany =
    ApplyCampaignType.equity_company === applyCampaignType;
  const isRealEstate = ApplyCampaignType.real_estate === applyCampaignType;
  return lead ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isEquityCompany && isApplicationAnswersLeadEquityV2(lead) && (
          <>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage messages={messages} messageId="webUrl" />
              {lead?.webUrl ? (
                <a href={lead.webUrl} target="blank">
                  <b>{lead.webUrl}</b>
                </a>
              ) : (
                <b>-</b>
              )}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="companyMaturity"
              />
              <b>
                {lead?.companyMaturity ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={lead.companyMaturity}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="raiseDateMaxMonths"
              />
              <b>
                {lead?.raiseDateMaxMonths ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={`${lead.raiseDateMaxMonths}`}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage messages={messages} messageId="goalRangeMax" />
              <b>
                {lead?.goalRangeMax ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={`${lead.goalRangeMax}`}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="crowdfundingKnowledge"
                isHtml
              />
              <b>
                {lead?.crowdfundingKnowledge ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={lead?.crowdfundingKnowledge}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="crowdfundingGoal"
              />
              <b>
                {lead?.crowdfundingGoal ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={lead.crowdfundingGoal}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage messages={messages} messageId="founderSkills" />
              <b>
                {lead?.founder?.skills ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={lead?.founder?.skills}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="linkedinProfileURL"
              />
              <b>
                {lead?.founder?.linkedinProfileURL ? (
                  <a href={lead?.founder?.linkedinProfileURL} target="_blank">
                    {lead?.founder?.linkedinProfileURL}
                  </a>
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="companyDescription"
              />
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <b>{lead?.businessDescription?.companyDescription || '-'}</b>
            </Typography>

            <Typography variant="body2" color="textPrimary">
              <FormattedMessage messages={messages} messageId="industry" />
              <IndustryText
                industryId={lead?.businessDescription?.industry}
                isInWeb
                bold
              />
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage messages={messages} messageId="product" />
              <b>
                {lead?.businessDescription?.product ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={lead?.businessDescription?.product}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="capitalRaisedSource"
              />
              <b>
                {lead?.equityHistory?.capitalRaisedSource ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={lead?.equityHistory?.capitalRaisedSource}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="existingInvestors"
              />
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <b>{lead?.equityHistory?.existingInvestors || '-'}</b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="capitalRaisedAmount"
              />
              <b>
                {lead?.equityHistory?.capitalRaisedAmount
                  ? renderEuroFloat(
                  parseFloat(lead?.equityHistory?.capitalRaisedAmount), // eslint-disable-line
              ) // eslint-disable-line
                  : '-'}
              </b>
            </Typography>
          </>
        )}
        {isRealEstate && isApplicationAnswersLeadRealEstateV2(lead) && (
          <>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage messages={messages} messageId="type" />
              <b>
                {lead?.type ? (
                  <FormattedMessage messages={messages} messageId={lead.type} />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage messages={messages} messageId="location" />
              <b>
                {lead?.location ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={lead.location}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="realEstateRaisePeriodMaxMonths"
              />
              <b>
                {lead?.raisePeriodMaxMonths ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={`re_${lead.raisePeriodMaxMonths}`}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="raiseDateMaxMonths"
              />
              <b>
                {lead?.raiseDateMaxMonths ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={`${lead.raiseDateMaxMonths}`}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage messages={messages} messageId="goalRangeMax" />
              <b>
                {lead?.goalRangeMax ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={`${lead.goalRangeMax}`}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="crowdfundingKnowledge"
                isHtml
              />
              <b>
                {lead?.crowdfundingKnowledge ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={lead?.crowdfundingKnowledge}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="crowdfundingGoal"
              />
              <b>
                {lead?.crowdfundingGoal ? (
                  <FormattedMessage
                    messages={messages}
                    messageId={lead.crowdfundingGoal}
                  />
                ) : (
                  '-'
                )}
              </b>
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  ) : null;
}

export default ApplyLeadPreview;
