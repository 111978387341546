/**
 *
 * CampaignApplicationViewPage
 *
 */

import { ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useRouteMatch } from 'react-router-dom';
import { useInjectSaga, useInjectReducer } from 'redux-injectors';
import { Loader, Typography } from '@mamacrowd/ui-kit';
import { Grid } from '@mui/material';

import FormattedMessage from 'components/FormattedMessage';
import PageLayout from 'components/PageLayout';
import ErrorForm from 'components/ErrorForm';
import ApplyLegalRepresentativePreview from 'components/ApplyLegalRepresentativePreview';
import ApplyCompanyProfilePreview from 'components/ApplyCompanyProfilePreview';
import ApplyLeadPreview from 'components/ApplyLeadPreview';

import {
  ApplyCampaignType,
  isApplicationV2ApplyAnswer,
  CampaignApplicationGetByIdRes,
} from 'types/Application';
import { UserProfile as UserProfileType } from 'types/UserProfile';

import EnumPageLayout from 'utils/Enums/PageLayout';

import UserProfile from 'images/PageIcons/natural_person.svg';
import ApplyCompany from 'images/PageIcons/apply-company.svg';
import ApplyRealEstate from 'images/PageIcons/apply-real-estate.svg';
import IconRaise from 'images/PageIcons/raise.svg';
import SelectInvestor from 'images/PageIcons/invest.svg';

import {
  makeSelectCampaignApplicationViewPageLoading,
  makeSelectCampaignApplicationViewPageApplication,
  makeSelectCampaignApplicationViewPageError,
  makeSelectCampaignApplicationViewPageUserProfile,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import { cleanApplication, loadApplication } from './actions';
import { useStyle } from './CampaignApplicationViewPage.style';

import { FEParsedError } from 'types/Error';
import ApplyLeadStep2Preview from 'components/ApplyLeadStep2Preview';

function CampaignApplicationViewSection({
  img,
  title,
  children,
}: {
  img: ReactNode;
  title: string;
  children: ReactNode;
}) {
  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent={'flex-start'}
      >
        <Grid item>{img}</Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="body1" color="textPrimary">
            <b>
              <FormattedMessage messages={messages} messageId={title} />
            </b>
          </Typography>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

export interface CampaignApplicationViewPageProps {
  loading: boolean;
  error: boolean | FEParsedError;
  application: CampaignApplicationGetByIdRes | null;
  userProfile: UserProfileType | null;
  loadCampaignApplicationData: (applicationId: string) => void;
  cleanCampaignApplicationData: () => void;
}

export function CampaignApplicationViewPage({
  loading,
  error,
  application,
  userProfile,
  loadCampaignApplicationData,
  cleanCampaignApplicationData,
}: CampaignApplicationViewPageProps) {
  useInjectReducer({ key: 'campaignApplicationViewPage', reducer });
  useInjectSaga({ key: 'campaignApplicationViewPage', saga });

  const match = useRouteMatch();

  const { applicationId } = match.params;

  useEffect(() => {
    if (typeof loadCampaignApplicationData === 'function' && applicationId) {
      loadCampaignApplicationData(applicationId);
    }
    return () => {
      cleanCampaignApplicationData();
    };
  }, [loadCampaignApplicationData]);

  const classes = useStyle();

  return (
    <PageLayout
      type={EnumPageLayout.withIcon}
      title={
        <FormattedMessage
          messages={messages}
          messageId="yourCompanyApplication"
        />
      }
      subTitle={
        <FormattedMessage messages={messages} messageId="applicationDetails" />
      }
      image={IconRaise}
    >
      <Grid container spacing={2}>
        {loading && (
          <Grid item xs={12}>
            <Loader isLoading mode="remaining-view" />
          </Grid>
        )}
        {userProfile && (
          <CampaignApplicationViewSection
            img={
              <img
                src={UserProfile}
                alt="user-profile"
                width={100}
                className={classes.imgWithBorder}
              />
            }
            title="legalRepresentative"
          >
            <ApplyLegalRepresentativePreview userProfile={userProfile} />
          </CampaignApplicationViewSection>
        )}
        {application && (
          <>
            {application?.answers?.companyProfile && (
              <CampaignApplicationViewSection
                img={
                  <img
                    src={
                      application.type === 'real_estate'
                        ? ApplyRealEstate
                        : ApplyCompany
                    }
                    alt={
                      application.type === 'real_estate'
                        ? 'real_estate'
                        : 'equity_company'
                    }
                    className={classes.imgWithBorder}
                  />
                }
                title="companyDetails"
              >
                <ApplyCompanyProfilePreview
                  companyProfile={application.answers.companyProfile}
                />
              </CampaignApplicationViewSection>
            )}
            {isApplicationV2ApplyAnswer(application.answers) && (
              <CampaignApplicationViewSection
                img={
                  <img
                    src={SelectInvestor}
                    alt="compiled_form"
                    className={classes.imgWithBorder}
                  />
                }
                title="projectDescription"
              >
                <ApplyLeadPreview
                  lead={application?.answers?.lead}
                  applyCampaignType={application?.type}
                />
              </CampaignApplicationViewSection>
            )}
            {ApplyCampaignType.equity_company === application.type && (
              <CampaignApplicationViewSection
                img={
                  <img
                    src={SelectInvestor}
                    alt="compiled_form"
                    className={classes.imgWithBorder}
                  />
                }
                title="step2"
              >
                <ApplyLeadStep2Preview
                  leadStep2={application?.answers?.leadStep2}
                />
              </CampaignApplicationViewSection>
            )}
          </>
        )}
        {error && (
          <Grid item xs={12}>
            <ErrorForm
              errorMessage={
                <FormattedMessage messages={messages} messageId="errorText" />
              }
              details={error && error !== true ? error?.details : undefined}
            />
          </Grid>
        )}
      </Grid>
    </PageLayout>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectCampaignApplicationViewPageLoading(),
  error: makeSelectCampaignApplicationViewPageError(),
  application: makeSelectCampaignApplicationViewPageApplication(),
  userProfile: makeSelectCampaignApplicationViewPageUserProfile(),
});

function mapDispatchToProps(dispatch) {
  return {
    loadCampaignApplicationData: (applicationId: string) => {
      dispatch(loadApplication(applicationId));
    },
    cleanCampaignApplicationData: () => {
      dispatch(cleanApplication());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CampaignApplicationViewPage);
