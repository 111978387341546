import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollRestoration() {
  const pathname = useLocation();
  const queryParamRegex = /[?&](tab|section|faq)=/i; // find if pathname contains tab, section or faq
  useEffect(() => {
    if (!(pathname.hash || queryParamRegex.test(pathname.search))) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

export default ScrollRestoration;
