import ReactGA4 from 'react-ga4';
import User from 'utils/User';
import ENUMCampaignStatus from 'utils/Enums/CampaignStatus';
import {
  getCookie,
  getCookieAnalytics,
  getCookieThirdParty,
} from 'utils/cookie';
import pkgInfo from '../../../package.json';
import { Campaign } from 'types/Campaign';

// const isProduction = process.env.NODE_ENV === 'production';
// const trackersDebug = !isProduction && process.env.DEBUG_TRACKERS && process.env.DEBUG_TRACKERS.toLowerCase() === 'true'; // eslint-disable-line prettier/prettier

const getItem = (items: Campaign[], amount?: string) => {
  const itemsArray: Campaign[] = items;
  return itemsArray.map((i: Campaign) => {
    const price = amount ? parseFloat(amount) : 0;
    return {
      item_id: i.id,
      item_name: i.title,
      item_list_name: 'Mamacrowd',
      item_category: i.type,
      item_category2: i?.company?.industry?.title || '',
      quantity: 1,
      price,
    };
  });
};

const getServerSideInfo = () => {
  if (!getCookieAnalytics()) return {};
  const fbc = getCookie('mmfc');
  let user_data = {};
  if (User.isLogged()) {
    user_data = {
      firstName: User.getUserProp('firstName') || '',
      lastName: User.getUserProp('lastName') || '',
      phone_number: User.getUserProp('phone') || '',
      email: User.getUserProp('email') || '',
    };
  }
  return {
    user_data,
    fbc,
  };
};

function getCampaignTrackers(campaign) {
  if (
    campaign &&
    [ENUMCampaignStatus.comingSoon, ENUMCampaignStatus.published].includes(
      campaign.status,
    ) &&
    getCookieThirdParty()
  ) {
    return {
      ga_vendor_id: campaign?.info?.trackers?.ga4 || '',
      fb_vendor_id: campaign?.info?.trackers?.facebook || '',
      gads_vendor_id: campaign?.info?.trackers?.gads?.conversionId || '', // `gadsid-${campaign.systemTitle}`,
      gads_vendor_label: campaign?.info?.trackers?.gads?.purchaseLabel || '', // `gadslabel-${campaign.systemTitle}`,
      linkedin_vendor_account_id:
        campaign?.info?.trackers?.linkedIn?.accountNumber || '',
    };
  }
  return {};
}

const GoogleTracker = {
  init: () => {
    ReactGA4.gtag('consent', 'default', {
      wait_for_update: 500,
      // COOKIE FUNCTIONAL
      functionality_storage: 'granted', // Abilita l'archiviazione che supporta le funzionalità del sito web o dell'app, come le impostazioni della lingua.
      security_storage: 'granted', // Consente l'archiviazione di informazioni relative alla sicurezza, ad esempio la funzionalità di autenticazione, la prevenzione delle attività fraudolente e altre protezioni per gli utenti.
      analytics_storage: 'granted', // Abilita l'archiviazione, ad esempio i cookie, relativi all'analisi (ad esempio la durata della visita).
      // COOKIE ANALYTICS
      ad_storage: 'denied', // Consente l'archiviazione di informazioni, ad esempio i cookie, relativi alla pubblicità.
      personalization_storage: 'denied', // Abilita l'archiviazione relativa alla personalizzazione, ad esempio i suggerimenti sui video.
      // Cookies added with consent mode v2
      ad_user_data: 'denied', // Imposta il consenso per l'invio a Google dei dati utente relativi alla pubblicità.
      ad_personalization: 'denied', // Imposta il consenso per la pubblicità personalizzata.
    });

    if (process.env.GA_4_TRACKER) {
      ReactGA4.initialize(process.env.GA_4_TRACKER, {
        testMode: process.env.NODE_ENV === 'test',
        gaOptions: {
          name: 'mamacrowd',
        },
        gtagOptions: {
          send_page_view: false,
          transport_url: 'https://sgtm.mamacrowd.com/',
          first_party_collection: true,
          request_type: 'client',
        },
      });
      ReactGA4.set({
        send_page_view: false,
        ads_data_redaction: true,
        currency: 'EUR',
        user_properties: {
          mama_version: pkgInfo.version,
        },
      });
    }
  },

  setUser: (uuid: string) => {
    if (getCookieAnalytics()) {
      ReactGA4.set({ user_id: uuid });
    }
  },

  toggleConsent: (cookieAnalytics: boolean) => {
    ReactGA4.gtag('consent', 'update', {
      ad_storage: cookieAnalytics ? 'granted' : 'denied',
      personalization_storage: cookieAnalytics ? 'granted' : 'denied',
      ad_user_data: cookieAnalytics ? 'granted' : 'denied',
      ad_personalization: cookieAnalytics ? 'granted' : 'denied',
    });
  },

  trackSignUp: (isConfirmed: boolean, method: string) => {
    ReactGA4.event(isConfirmed ? 'sign_up_confirmed' : 'sign_up', {
      method,
    });
  },

  trackLogin: (method: string) => {
    ReactGA4.event('login', { method });
  },

  trackLogout: () => {
    ReactGA4.event('logout');
  },

  trackShare: (campaign: Campaign, method: string) => {
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('share', {
      method,
      item_id: campaign.id,
      ...serverSideInfo,
    });
  },

  trackCustomEvent: (
    action: string,
    label: string,
    category: string,
    value?: string,
  ) => {
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event({
      action,
      label,
      category,
      value: value ? parseFloat(value) : undefined,
      ...serverSideInfo,
    });
  },

  trackPageView: (path: string) => {
    const serverSideInfo = getServerSideInfo();
    ReactGA4.send({
      hitType: 'pageview',
      page: path,
      ...serverSideInfo,
    });
  },

  trackCampaignView: (campaign: Campaign) => {
    const serverSideInfo = getServerSideInfo();
    const campaignTrackingId = getCampaignTrackers(campaign);
    ReactGA4.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: campaign.title || document.title,
      ...campaignTrackingId,
      ...serverSideInfo,
    });
  },

  trackCampaignViewSection: (
    campaignId: string,
    tab: string,
    section: string,
  ) => {
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('view_campaign_section', {
      campaignId,
      tab,
      section,
      ...serverSideInfo,
    });
  },

  trackCampaignViewTab: (campaignId: string, tab: string) => {
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('view_campaign_tab', {
      campaignId,
      tab,
      ...serverSideInfo,
    });
  },

  trackViewItemList: (campaigns: Campaign[]) => {
    const itemsList = getItem(campaigns);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('view_item_list', {
      item_list_name: 'Mamacrowd',
      items: itemsList,
      ...serverSideInfo,
    });
  },

  trackSelectItem: (campaign: Campaign) => {
    const item = getItem([campaign]);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('select_item', { items: item, ...serverSideInfo });
  },

  trackViewItem: (campaign: Campaign) => {
    const item = getItem([campaign]);
    const campaignTrackingId = getCampaignTrackers(campaign);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('view_item', {
      items: item,
      ...campaignTrackingId,
      ...serverSideInfo,
    });
  },

  trackAddToWishlist: (campaign: Campaign) => {
    const item = getItem([campaign]);
    const campaignTrackingId = getCampaignTrackers(campaign);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('add_to_wishlist', {
      items: item,
      ...campaignTrackingId,
      ...serverSideInfo,
      linkedin_vendor_conversion_id:
        campaign?.info?.trackers?.linkedIn?.conversionFollowId || '',
    });
  },

  trackAddToCart: (campaign: Campaign) => {
    const item = getItem([campaign]);
    const campaignTrackingId = getCampaignTrackers(campaign);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('add_to_cart', {
      items: item,
      ...campaignTrackingId,
      ...serverSideInfo,
      linkedin_vendor_conversion_id:
        campaign?.info?.trackers?.linkedIn?.conversionCommitmentBeginId || '',
    });
  },

  trackRemoveToCart: (campaign: Campaign) => {
    const item = getItem([campaign]);
    const campaignTrackingId = getCampaignTrackers(campaign);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('remove_from_cart', {
      items: item,
      ...campaignTrackingId,
      ...serverSideInfo,
    });
  },

  trackBeginCheckout: (campaign: Campaign, amount: string) => {
    const item = getItem([campaign], amount);
    const campaignTrackingId = getCampaignTrackers(campaign);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('begin_checkout', {
      value: amount,
      items: item,
      ...campaignTrackingId,
      ...serverSideInfo,
    });
  },

  trackStepInvestorType: (campaign: Campaign, investorType: string) => {
    const item = getItem([campaign]);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('order_step_investor_type', {
      campaignId: campaign.id,
      investorType,
      items: item,
      ...serverSideInfo,
    });
  },

  trackStepUserProfile: (campaign: Campaign, userProfileId: string) => {
    const item = getItem([campaign]);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('order_step_user_profile', {
      campaignId: campaign.id,
      userProfileId,
      items: item,
      ...serverSideInfo,
    });
  },

  trackStepBusinessProfile: (campaign: Campaign, businessProfileId: string) => {
    const item = getItem([campaign]);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('order_step_business_profile', {
      campaignId: campaign.id,
      businessProfileId,
      items: item,
      ...serverSideInfo,
    });
  },

  trackStepKnowledgeTest: (
    campaign: Campaign,
    investorKnowledgeTestId: string,
  ) => {
    const item = getItem([campaign]);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('order_step_knowledge_test', {
      campaignId: campaign.id,
      investorKnowledgeTestId,
      items: item,
      ...serverSideInfo,
    });
  },

  trackStepBearLossesSimulation: (
    campaign: Campaign,
    investorKnowledgeTestId: string,
  ) => {
    const item = getItem([campaign]);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('order_step_bear_losses_simulation', {
      campaignId: campaign.id,
      investorKnowledgeTestId,
      items: item,
      ...serverSideInfo,
    });
  },

  trackPurchase: (campaign: Campaign, amount: string, commitmentId: string) => {
    const item = getItem([campaign], amount);
    const campaignTrackingId = getCampaignTrackers(campaign);
    const serverSideInfo = getServerSideInfo();
    ReactGA4.event('purchase', {
      transaction_id: commitmentId,
      value: amount,
      items: item,
      ...campaignTrackingId,
      ...serverSideInfo,
      linkedin_vendor_conversion_id:
        campaign?.info?.trackers?.linkedIn?.conversionPurchaseId || '',
    });
  },

  trackInterest: (campaign: Campaign, value: string) => {
    const serverSideInfo = getServerSideInfo();
    const campaignTrackingId = getCampaignTrackers(campaign);
    ReactGA4.event('campaign_interest', {
      value,
      campaign: campaign.title,
      ...campaignTrackingId,
      ...serverSideInfo,
      linkedin_vendor_conversion_id:
        campaign?.info?.trackers?.linkedIn?.conversionInterestId || '',
    });
  },

  trackSpecifyAmount: (campaign: Campaign, amount: string) => {
    const serverSideInfo = getServerSideInfo();
    const campaignTrackingId = getCampaignTrackers(campaign);
    ReactGA4.event('specify_amount', {
      value: amount,
      campaign: campaign.title,
      ...campaignTrackingId,
      ...serverSideInfo,
      linkedin_vendor_conversion_id:
        campaign?.info?.trackers?.linkedIn
          ?.conversionCommitmentSpecifyAmountId || '',
    });
  },
};

export default GoogleTracker;
