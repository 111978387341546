import Logger from 'loglevel';
import * as Sentry from '@sentry/react';
import { matchPath } from 'react-router';

import makeApiUrl from 'utils/makeApiUrl';
import User from 'utils/User'; // eslint-disable-line import/no-cycle
import { Campaign, CampaignType } from 'types/Campaign';
import {
  CAMPAIGN_ROUTE,
  LOG_AWS_TRACK_EXCEPTION_ENABLED,
  LOG_ERROR_URL,
} from 'utils/constants';
import { getCookieAnalytics, getCookieSupport } from 'utils/cookie';

import GA4Tracker from './ga4';
import pkgInfo from '../../../package.json';
import {
  ApplyCampaignType,
  CampaignApplicationGetByIdRes,
} from 'types/Application';

const IC_ACTION_SIGNUP = 'registration';
const IC_ACTION_LOGIN = 'login';
const IC_ACTION_LOGOUT = 'logout';
const IC_ACTION_USER_ACTIVATE = 'registration_confirmed';
const IC_ACTION_CAMPAIGN_VIEW = 'campaign_view';
const IC_ACTION_DOCUMENT_DOWNLOAD = 'document_download';
const IC_ACTION_CAMPAIGN_FOLLOW = 'campaign_follow';
const IC_ACTION_CAMPAIGN_UNFOLLOW = 'campaign_unfollow';
const IC_ACTION_CAMPAIGN_INTEREST = 'campaign_interest';
const IC_ACTION_COMMITMENT_BEGIN = 'commitment_begin';
const IC_ACTION_COMMITMENT_SPECIFY_AMOUNT = 'commitment_specify_equity';
const IC_ACTION_COMMITMENT_ORDER_PROCEED = 'commitment_order_proceed';
const IC_ACTION_COMMITMENT_USER_TYPE = 'commitment_specify_user_type';
const IC_ACTION_COMMITMENT_USER_PROFILE = 'commitment_save_user_profile';
const IC_ACTION_COMMITMENT_BUSINESS_PROFILE =
  'commitment_save_business_profile';
const IC_ACTION_COMMITMENT_ACCEPT_ADVICE = 'appropriateness_accept_advice';
const IC_ACTION_COMMITMENT_MIFID = 'appropriateness_complete';
const IC_ACTION_COMMITMENT_COMPLETE = 'investment_complete';

const isProduction = process.env.NODE_ENV === 'production';

function isInUrlBlackList(url: string) {
  const blackListPaths = [CAMPAIGN_ROUTE];
  return blackListPaths.some(path => {
    const match = matchPath(url, { path });
    return match?.isExact || false;
  });
}

function getList(campaign: Campaign) {
  switch (campaign.type) {
    case CampaignType.realEstate:
    case CampaignType.realEstateIncome:
    case CampaignType.equity:
    default:
      return 'Mamacrowd';
  }
}

function sendIntercomEvent(event: string, metadata: Record<string, unknown>) {
  if (isProduction && window.Intercom && getCookieSupport())
    window.Intercom('trackEvent', event, metadata);
}

function trackIntercomEvent(
  campaign: Campaign,
  action: string,
  options: Record<string, unknown>,
) {
  const { amount = 0, investorType = '', intercom } = options;
  sendIntercomEvent(action, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
    campaignList: getList(campaign),
    commitmentType: campaign.type,
    commitmentAmount: parseFloat(`${amount}`),
    ...(investorType ? { commitmentUserType: investorType } : {}),
    ...(intercom || {}),
  });
}

function setUser(userId?: string) {
  const uid = User.getUserProp('id') || userId || '';
  GA4Tracker.setUser(uid);
}

export function toggleConsent(cookieAnalytics: boolean) {
  GA4Tracker.toggleConsent(cookieAnalytics);
}

export function trackersInit() {
  // GA4 init
  GA4Tracker.init();
  toggleConsent(getCookieAnalytics());
}

export function trackEvent(
  action: string,
  label: string,
  category: string,
  value?: string,
) {
  GA4Tracker.trackCustomEvent(action, label, category, value);
}

export function trackSignUp(
  uuid: string,
  isConfirmed: boolean,
  method: string,
) {
  setUser(uuid);
  GA4Tracker.trackSignUp(isConfirmed, method);
  sendIntercomEvent(isConfirmed ? IC_ACTION_USER_ACTIVATE : IC_ACTION_SIGNUP, {
    label: method,
    userId: uuid || '',
  });
}

export function trackLogin(uid: string, method: string) {
  setUser();
  GA4Tracker.trackLogin(method);
  sendIntercomEvent(IC_ACTION_LOGIN, {
    label: method,
    userId: uid || '',
  });
}

export function trackLogout() {
  setUser();
  GA4Tracker.trackLogout();
  sendIntercomEvent(IC_ACTION_LOGOUT, {
    label: 'Logout',
    userId: User.getUserProp('id') || '',
  });
}

export function trackPageView(path: string) {
  if (!isInUrlBlackList(path.substring(3))) {
    setUser();
    GA4Tracker.trackPageView(path);
  }
}

export function trackViewItemList(campaignsList: Campaign[]) {
  GA4Tracker.trackViewItemList(campaignsList);
}

export function trackSelectItem(campaign: Campaign) {
  GA4Tracker.trackSelectItem(campaign);
}

export function trackCampaignView(campaign: Campaign) {
  setUser();
  GA4Tracker.trackCampaignView(campaign);
  GA4Tracker.trackViewItem(campaign);
  sendIntercomEvent(IC_ACTION_CAMPAIGN_VIEW, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
  });
}

export function trackCampaignViewSection(
  campaignId: string,
  tab: string,
  section: string,
) {
  setUser();
  GA4Tracker.trackCampaignViewSection(campaignId, tab, section);
}

export function trackCampaignViewTab(campaignId: string, tab: string) {
  setUser();
  GA4Tracker.trackCampaignViewTab(campaignId, tab);
}

export function trackCampaignFollow(campaign: Campaign) {
  setUser();
  GA4Tracker.trackAddToWishlist(campaign);
  sendIntercomEvent(IC_ACTION_CAMPAIGN_FOLLOW, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
  });
}

export function trackCampaignInterest(campaign: Campaign, value: number) {
  GA4Tracker.trackInterest(campaign, `${value}`);
  sendIntercomEvent(IC_ACTION_CAMPAIGN_INTEREST, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
    amount: value,
  });
}

export function trackCampaignUnfollow(campaign: Campaign) {
  sendIntercomEvent(IC_ACTION_CAMPAIGN_UNFOLLOW, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
  });
}

export function trackCommitmentAmount(campaign: Campaign, amount: string) {
  Logger.log(['TRACK AMOUNT', campaign, amount]);
  setUser();
  GA4Tracker.trackSpecifyAmount(campaign, amount);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_SPECIFY_AMOUNT, { amount });
}

export function trackCommitmentBegin(campaign: Campaign, amount: string) {
  Logger.log(['0 TRACK STEP BEGIN', campaign, amount]);
  setUser();
  GA4Tracker.trackAddToCart(campaign);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_BEGIN, { amount });
}

export function trackCommitmentOrderProceed(
  campaign: Campaign,
  amount: string,
) {
  Logger.log(['1 TRACK STEP ORDER PROCEED', campaign, amount]);
  setUser();
  GA4Tracker.trackBeginCheckout(campaign, amount);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_ORDER_PROCEED, { amount });
}

export function trackCommitmentInvestorType(
  campaign: Campaign,
  amount: string,
  investorType: string,
) {
  Logger.log(['2 TRACK STEP USER TYPE', campaign, amount, investorType]);
  setUser();
  GA4Tracker.trackStepInvestorType(campaign, investorType);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_USER_TYPE, {
    amount,
    investorType,
  });
}

export function trackCommitmentUserProfile(
  campaign: Campaign,
  userProfileId: string,
  amount: string,
  investorType: string,
) { // eslint-disable-line prettier/prettier
  Logger.log(['3 TRACK STEP USER PROFILE', campaign, amount, investorType]); // eslint-disable-line prettier/prettier
  setUser();
  GA4Tracker.trackStepUserProfile(campaign, userProfileId);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_USER_PROFILE, {
    amount,
    investorType,
  });
}

export function trackCommitmentBusinessProfile(
  campaign: Campaign,
  businessProfileId: string,
  amount: string,
  investorType: string,
) { // eslint-disable-line prettier/prettier
  Logger.log(['4 TRACK STEP BUSINESS PROFILE', campaign, amount, investorType]); // eslint-disable-line prettier/prettier
  setUser();
  GA4Tracker.trackStepBusinessProfile(campaign, businessProfileId);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_BUSINESS_PROFILE, {
    amount,
    investorType,
  });
}

export function trackCommitmentKnowledge(
  campaign: Campaign,
  investorKnowledgeTestId: string,
  amount: string,
  investorType: string,
) { // eslint-disable-line prettier/prettier
  Logger.log(['5 TRACK STEP KNOWLEDGE', campaign, amount, investorType]);
  setUser();
  GA4Tracker.trackStepKnowledgeTest(campaign, investorKnowledgeTestId);
  // trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_KNOWLEDGE_TEST, {
  //   amount,
  //   investorType,
  // });
}

export function trackCommitmentBearLossesSimulation(
  campaign: Campaign,
  investorKnowledgeTestId: string,
  amount: string,
  investorType: string,
) { // eslint-disable-line prettier/prettier
  Logger.log(['5 TRACK STEP BEAR LOSSES SIMULATION', campaign, amount, investorType]); // eslint-disable-line prettier/prettier
  setUser();
  GA4Tracker.trackStepBearLossesSimulation(campaign, investorKnowledgeTestId);
  // trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_BEAR_LOSSES_SIMULATION, {
  //   amount,
  //   investorType,
  // });
}

export function trackCommitmentMifid(
  campaign: Campaign,
  amount: string,
  investorType: string,
) {
  Logger.log(['5 TRACK STEP MIFID', campaign, amount, investorType]);
  setUser();
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_MIFID, {
    amount,
    investorType,
  });
}

export function trackCommitmentReviewAndAccept(
  campaign: Campaign,
  amount: string | number,
  investorType: string,
) {
  Logger.log(['6 TRACK STEP REVIEW & ACCEPT', campaign, amount, investorType]); // eslint-disable-line prettier/prettier
  setUser();
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_ACCEPT_ADVICE, {
    amount: `${amount}`,
    investorType,
  });
}

export function trackCommitmentComplete(
  campaign: Campaign,
  amount: string,
  commitmentId: string,
) {
  Logger.log(['7 TRACK ORDER COMPLETE', campaign, amount, commitmentId]);
  setUser();
  GA4Tracker.trackPurchase(campaign, amount, commitmentId);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_COMPLETE, {
    amount,
    intercom: {
      commitmentId,
      commitmentCurrency: 'EUR',
    },
  });
}

export function trackDocumentDownload(
  campaign: Campaign,
  document: { id: string; title: string },
) {
  setUser();
  sendIntercomEvent(IC_ACTION_DOCUMENT_DOWNLOAD, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
    documentId: document.id,
    documentName: document.title,
  });
}

export function trackException(description: string) {
  if (LOG_AWS_TRACK_EXCEPTION_ENABLED) {
    try {
      const logErrorUrl = makeApiUrl(LOG_ERROR_URL);
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      if (User.getUserToken()) {
        headers.append('Authorization', `Bearer ${User.getUserToken()}`);
      }
      window.fetch(logErrorUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          message: description,
          version: pkgInfo.version,
          details: {
            userId: User.getUserProp('id') || '',
            environment: process.env.NODE_ENV,
            page: {
              location: window.location.href,
              search: window.location.search,
              referrer: document.referrer,
            },
            navigator: window.navigator
              ? {
                userAgent: window.navigator.userAgent, // eslint-disable-line
                vendor: window.navigator.vendor, // eslint-disable-line
              } : null, // eslint-disable-line
            timestamp: Date.now(),
          },
        }),
      });
    } catch (e) {
      Logger.log([e, description]);
    }
  }
}

export function trackLog(
  level: 'error' | 'warning' | 'info' | 'debug' | 'critical',
  message: string,
) {
  switch (level) {
    case 'error':
      Sentry.captureMessage(message, 'error');
      break;
    case 'warning':
      Sentry.captureMessage(message, 'warning');
      break;
    case 'info':
      Sentry.captureMessage(message, 'info');
      break;
    case 'debug':
      Sentry.captureMessage(message, 'debug');
      break;
    case 'critical':
      Sentry.captureMessage(message, 'error');
      break;
    default:
      Sentry.captureMessage(message, level);
      break;
  }
}

export function trackError(error: any, props?: Record<string, unknown>) {
  Sentry.withScope(scope => {
    if (props) {
      Object.keys(props).forEach(key => {
        scope.setExtra(key, props[key]);
      });
    }
    Sentry.captureException(error);
  });
}

// ########################################
// ##### DEPRECATED TRACKER FUNCTIONS #####
// ########################################

export function trackLeadApplicationBegin(
  type: CampaignApplicationGetByIdRes['type'],
) {
  Logger.log(['0 TRACK APPLICATION STEP BEGIN', type]);
  trackEvent('LeadStart', type, 'LeadApplication');
  // GoogleTracker.trackLeadApplicationBegin(type);
}

export function trackLeadApplicationStepUser(
  type: ApplyCampaignType,
  fiscalCode?: string,
) {
  Logger.log(['1 TRACK APPLICATION STEP USER', type, fiscalCode]);
  trackEvent('LeadSelectUser', type, 'LeadApplication');
  // GoogleTracker.trackLeadApplicationStepUser(type, fiscalCode);
}

export function trackLeadApplicationStepCompany(
  type: ApplyCampaignType,
  vatNumber?: string,
) {
  Logger.log(['2 TRACK APPLICATION STEP COMPANY', type, vatNumber]);
  trackEvent('LeadSelectCompany', type, 'LeadApplication');
  // GoogleTracker.trackLeadApplicationStepCompany(type, vatNumber);
}

export function trackLeadApplicationStepLead(
  type: ApplyCampaignType,
  vatNumber?: string,
) {
  Logger.log(['3 TRACK APPLICATION STEP PROJECT', type, vatNumber]);
  trackEvent('LeadStep1', type, 'LeadApplication');
  // GoogleTracker.trackLeadApplicationStepLead(type, vatNumber);
}

export function trackLeadApplicationComplete(
  type: ApplyCampaignType,
  vatNumber?: string,
) {
  Logger.log(['9 TRACK APPLICATION STEP COMPLETE', type, vatNumber]);
  trackEvent('LeadStep1Complete', type, 'LeadApplication');
  // GoogleTracker.trackLeadApplicationComplete(type, vatNumber);
}

export function trackLeadStep2ApplicationComplete(
  type: ApplyCampaignType,
  vatNumber?: string,
) {
  Logger.log(['9 TRACK APPLICATION STEP 2 COMPLETE', type, vatNumber]);
  trackEvent('LeadStep2Complete', type, 'LeadApplication');
  // GoogleTracker.trackLeadApplicationComplete(type, vatNumber);
}

export function trackApplicationStepCompany(
  type: ApplyCampaignType,
  vatNumber?: string,
) {
  Logger.log(['2 TRACK APPLICATION STEP COMPANY', type, vatNumber]);
  // GoogleTracker.trackApplicationStepCompany(type, vatNumber);
}

export const trackCampaignOwnerKnowledgeSection = (ownerSection: string) => {
  Logger.log(ownerSection);
  // GoogleTracker.trackCustomEvent('click_knowledge_owner_section',ownerSection,'Nav'); // eslint-disable-line prettier/prettier
};

export const trackInvestorKnowledgeSection = (investorSection: string) => {
  Logger.log(investorSection);
  // GoogleTracker.trackCustomEvent('click_investor_section',investorSection,'Nav'); // eslint-disable-line prettier/prettier
};

export const trackChangeLanguage = (lang: string, userIsLogged: boolean) => {
  Logger.log(lang, userIsLogged);
  // GoogleTracker.trackCustomEvent(userIsLogged ? 'click_lang_profile' : 'click_lang_public',lang,'Nav'); // eslint-disable-line prettier/prettier
};

export function trackVideoStart(systemTitle: string, videoId?: string) {
  Logger.log(systemTitle, videoId);
  setUser();
}

export function trackVideoEnd(systemTitle: string, videoId?: string) {
  Logger.log(systemTitle, videoId);
  setUser();
}
