/**
 *
 * ErrorForm
 *
 */

import { ReactNode } from 'react';
import { Typography, TextProps, Alert, Tooltip } from '@mamacrowd/ui-kit';
import { Stack } from '@mui/material';
import Info from '@mui/icons-material/Info';

export interface ErrorFormProps {
  errorMessage: ReactNode | string;
  details?: string | null;
  align?: TextProps['align'];
  toast?: boolean;
  handleSnackbarClose?: () => void;
}

function ErrorForm({
  toast = false,
  handleSnackbarClose,
  errorMessage,
  details,
  align,
}: ErrorFormProps) {
  if (toast) {
    return (
      <Alert
        open={!!errorMessage}
        onClose={handleSnackbarClose}
        tooltipMessage={details || null}
      >
        {errorMessage}
      </Alert>
    );
  }

  if (errorMessage) {
    return (
      <Stack alignItems="center" direction="row" gap={1}>
        {details && (
          <Tooltip title={details} placement="top">
            <Info color="error" />
          </Tooltip>
        )}
        <Typography color="error" align={align || 'left'}>
          {errorMessage}
        </Typography>
      </Stack>
    );
  }

  return null;
}

export default ErrorForm;
