import { CommonColors, PaletteOptions, TypeText } from '@mui/material';

const text: TypeText = {
  primary: '#444444',
  secondary: '#B9B9B9',
  disabled: 'rgba(0,0,0,0.38)',
  // CUSTOM
  footerLink: '#949494',
  darkGray: '#777777',
};

const common: CommonColors = {
  black: '#444444',
  white: '#FFFFFF',
  // CUSTOM
  alternateRow: '#F5F5F5',
  borderColor: '#D8D8D8',
  bgHeaderDev: '#FFFFBE',
  bgPlaceholder: '#F3F3F3',
  bgToolbarEditor: '#EAEAEA',
  blogArticlePlaceholder: '#EAEAEA',
  bgCardAnimatedLoader: '#E9EBEE',
  bgLoading: '#DDDFE2',
  bgChamberSection: '#B20000',
  chamberText: '#FDFCFC',
  draftToolbarButton: '#686868',
  yellow: '#FFFFBE',
  bgSpidProvider: '#F0F0F0',
  linkedinBlue: '#0A66C2',
  darkGray: '#949494',
};

export const palette: PaletteOptions = {
  mode: 'light',
  text,
  common,
  primary: {
    dark: '#368485',
    main: '#4EBDBF',
    light: '#DDF4F5',
    contrastText: '#FFFFFF',
  },
  secondary: {
    dark: '#CCCCCC',
    main: '#949494',
    light: '#E8E8E8',
    contrastText: '#444444',
  },
  success: {
    dark: '#00986A',
    main: '#10BF56',
    light: '#C1F1E0',
    contrastText: '#FFFFFF',
  },
  error: {
    dark: '#E85644',
    main: '#FF0000',
    light: '#EECAC5',
    contrastText: '#FFFFFF',
  },
  warning: {
    dark: '#FF985A',
    main: '#FF985A',
    light: '#F8EEE8',
  },
  info: {
    dark: '#007AC1',
    main: '#006ADC',
    light: '#EDF6FF',
  },
  // CUSTOM
  earlyBird: {
    dark: '#007AC1',
    main: '#006ADC',
    light: '#EDF6FF',
    contrastText: '#FFFFFF',
  },
  youtube: {
    dark: '#C80000',
    main: '#F00000',
    light: '#FF6666',
  },
  background: {
    default: '#FFFFFF',
    primary: '#EDF8F8',
  },
  spid: {
    dark: '#00478F',
    main: '#0066CC',
    contrastText: '#FFFFFF',
  },
  webinar: {
    dark: '#9DBBC7',
    main: '#9DC7BD',
    light: '#95B0B1',
    contrastText: '#FFFFFF',
  },
  closing: {
    main: '#F0BF10',
    contrastText: '#FFFFFF',
  },
  closedFunded: {
    main: '#10BF56',
    contrastText: '#FFFFFF',
  },
  closedNotFunded: {
    main: '#949494',
    contrastText: '#FFFFFF',
  },
  exit: {
    main: '#B638CA',
    contrastText: '#FFFFFF',
  },
  equity: {
    dark: '#368485',
    main: '#4EBDBF',
    light: '#DDF4F5',
    contrastText: '#FFFFFF',
  },
  realEstate: {
    main: '#FF985A', // '#FFF3ED',
    light: '#FFF3ED', // '#FFF9F6',
    contrastText: '#FFFFFF',
  },
  equityFund: {
    main: '#5B7FFF',
    light: '#EDF1FF',
    contrastText: '#FFFFFF',
  },
  campaignOffline: {
    main: '#FF21CE',
    light: '#FEECFB',
    contrastText: '#FFFFFF',
  },
  restricted: {
    main: '#000000',
    light: '#000000',
    contrastText: '#444444',
  },
  disabled: {
    main: '#F1EFEF',
    light: '#F9F8F8',
    contrastText: '#444444',
  },
  // getContrastText: background => {
  //   // Equity
  //   if (background === '#EFFFFE') return '#444444';
  //   if (background === '#59D3C9') return '#FFFFFF';
  //   // Real Estate
  //   if (background === '#FFF3ED') return '#444444';
  //   if (background === '#FF985A') return '#FFFFFF';
  //   return '#444444';
  // },
};
