import { Company } from './Company';
import { Document } from './Document';
import { TeamMember } from './TeamMember';
import { Update } from './Update';
import { Quota } from './Quota';
import { Reward } from './Reward';
import { DescriptionSection } from './DescriptionSection';
import { Partner } from './Partner';
import { IRichDataEsponente, IRichDataSoci } from './RichdataDetails';
import { paths } from './webapi';
import { SimType } from './Sim';
import { Range } from './Aucap';
import { SellaCampaignStatus } from './Sella';

export enum BillingExtraType {
  Webinar = 'webinar',
  Penalty = 'penalty',
  Discount = 'discount',
  Sim = 'sim',
  TrustCompany = 'trust_company',
  FurtherSuccessFee = 'further_success_fee',
  Custom = 'custom',
}

export type BillingExtraItem = {
  id: string;
  createdAt: string;
  type: BillingExtraType;
  amount: string;
  description: string;
};

export type CrowdFeePercElement = {
  min: number;
  max?: number;
  feePerc: number;
};

export interface CampaignBillingParameters {
  updatedAt: string;
  upfrontFee: string;
  minimumFee: string;
  crowdFeePerc: CrowdFeePercElement[];
  fundFeePerc: string;
  anchorFeePerc: string;
  anchorPeriodDays: number;
  anchorMinimumTicket: string;
  anchorFeeOnSurplus: string;
  extras: BillingExtraItem[];
  notes?: string;
}

export enum FundedStatus {
  overfunded = 'overfunded',
  funded = 'funded',
  notFunded = 'not_funded',
}

export type FundingStatus = {
  fundedStatus: FundedStatus;
  statusOrdersApproved: boolean;
  statusOrdersProspect: boolean;
  statusProfessionalInvestorsApproved: boolean;
};

export enum AlgType {
  share = 'share',
  quota_v1 = 'quota_v1',
  quota_v2 = 'quota_v2',
  quota_v3 = 'quota_v3',
  // quota_not_modified = 'quota_not_modified',
}

export enum CampaignChildType {
  restricted = 'restricted',
  double_funding = 'double_funding',
}

type Trackers = {
  ga4: string | null;
  gads?: {
    conversionId: string;
    purchaseLabel: string;
  };
  google: string | null;
  facebook: string | null;
  facebookAPIAccessToken?: string;
  linkedIn: {
    partnerId: string | null;
    conversionFollowId: string | null;
    conversionInterestId: string | null;
    conversionPurchaseId: string | null;
    conversionCommitmentBeginId: string | null;
    conversionCommitmentSpecifyAmountId: string | null;
  } | null;
};

type OwnerEditStatus = 'editable' | 'locked_moderation' | 'locked';

export enum TransferType {
  ordinary = 'ordinary',
  cdt = 'cdt',
  trustCompany = 'trust_company',
}

export type Period =
  | 'three_months'
  | 'six_months'
  | 'nine_months'
  | 'twelve_months'
  | 'fifteen_months'
  | 'eighteen_months'
  | 'two_years'
  | 'two_half_years'
  | 'three_years'
  | 'five_years';

export type CampaignVideo = {
  id: string | null;
  type?: string;
};

export type CampaignInfo = {
  abstract: string;
  abstract_it?: string;
  comingSoonDescription?: string;
  comingSoonDescription_it?: string;
  comingSoonDescription_en?: string;
  coverImageFileKey?: string;
  coverImageUrl?: string;
  cardImageFileKey: string;
  cardImageUrl: string;
  video?: CampaignVideo;
  highlights: string[];
  trackers: Trackers;
  metaTitle: string;
  metaDescription: string;
};

export enum CampaignType {
  equity = 'equity',
  equity_sme = 'equity_sme',
  realEstate = 'realEstate',
  realEstateIncome = 'real_estate_income',
  equity_fund = 'equity_fund',
  minibond = 'minibond',
}

export enum CampaignStatus {
  onboarding = 'onboarding',
  comingSoon = 'coming_soon',
  published = 'published',
  closing = 'closing', // NEW STATE for closing procedures
  closedFunded = 'closed_succesfully',
  closedNotFunded = 'closed_not_ok',
  exit = 'exit',
  canceled = 'canceled',
  revoked = 'revoked',
}

export enum CampaignViewMode {
  full = 'full',
  limitedClosed = 'limitedClosed',
  limitedNotAuthenticated = 'limitedNotAuthenticated',
  limitedRestricted = 'limitedRestricted',
}

export type CampaignMetrics = {
  id: string;
  type: `${CampaignType}`;
  goalAmountMin: string | Range;
  goalAmountMax: string | Range;
  goalNominalMin: string | Range;
  goalNominalMax: string | Range;
  waitingForPaymentInvestmentOrders: number;
  waitingForPaymentInvestmentOrderAmount: string;
  waitingForPaymentInvestmentOrderNominal: string;
  approvedInvestmentOrders: number;
  approvedInvestmentOrderAmount: string;
  approvedInvestmentOrderNominal: string;
  refundedInvestmentOrders: number;
  refundedInvestmentOrdersAmount: string;
  refundedInvestmentOrdersNominal: string;
  canceledInvestmentOrders: number;
  canceledInvestmentOrdersAmount: string;
  canceledInvestmentOrdersNominal: string;
  toBeValidatedInvestmentOrders: number;
  toBeValidatedInvestmentOrdersAmount: string;
  toBeValidatedInvestmentOrdersNominal: string;
  waitingForPaymentProfessionalInvestmentOrdersAmount: string;
  waitingForPaymentProfessionalInvestmentOrdersNominal: string;
  approvedProfessionalInvestmentOrdersAmount: string;
  approvedProfessionalInvestmentOrdersNominal: string;
  approvedProfessionalInvestmentOrdersNominalDelta: string;
  totalInvestmentOrders: number;
  totalInvestmentOrderAmount: string;
  totalInvestmentOrderNominal: string;
  totalProspectInvestmentOrders: number;
  totalProspectInvestmentOrderAmount: string;
  totalProspectInvestmentOrderNominal: string;
  totalProspectInvestmentOrderAmountWithOffline: string;
  consolidatedInvestmentOrders: number;
  consolidatedInvestmentOrdersAmount: string;
  consolidatedInvestmentOrdersNominal: string;
  totalRaised: string;
  totalRaisedWithOffline: string;
  minQuotaValue: string;
  totalInvestors: number;
  totalProspectInvestors: number;
  dilutionPerc: string;
  professionalApprovedInvestmentPercentage: string;
  professionalApprovedInvestmentNominalPercentage: string;
  professionalWaitingForPaymentInvestmentPercentage: string;
  professionalWaitingForPaymentInvestmentNominalPercentage: string;
  professionalInvestmentCoverage: string | Range;
  professionalInvestmentNominalCoverage: string;
  professionalInvestmentNominalCoverageWarning: boolean;
  approvedInvestmentOrdersPercentage: string;
  approvedInvestmentOrdersNominalPercentage: string;
  fundingStatus: FundingStatus;
};

export type ProjectMetrics =
  paths['/campaign/{campaignId}/project/metrics']['get']['responses']['200']['content']['application/json']['projectMetrics'];

export type CampaignAccounting = {
  endingApproved: string;
  totalRaised: string;
};

type HistoryPrevData = {
  openDate: string;
  closeDate: string;
  goalAmountMax: string;
  goalAmountMin: string;
};

export type CampaignSimDetail = {
  threshold: number | null;
  paidByCompany?: boolean;
};

export type CampaignEarlyBirdRange = { min: number; max: number; name: string };

type EarlyBirdDetail = {
  expirationDays: number;
  type?: string;
  range?: CampaignEarlyBirdRange[];
};

export type SpvDetail = {
  preShareCapitalValue: string;
  preMoneyValue: string;
  dilutionPercMin: string;
  dilutionPercMax: string;
};

type HistoryDetail = {
  prevData: HistoryPrevData;
  updatedAt: string;
  userId: string;
};

export type ExitDetail = {
  lastRealEstateUpdateDate?: string;
  paymentDate?: string;
  returnsStartDate?: string;
  returnsEndDate?: string;
  ROIYearMax?: string;
  period?: number;
  annualDividend?: string;
  periodDays?: number;
  updatedForecastPeriod?: number;
  updatedForecastPeriodDays?: number;
};

export type CampaignDetail = {
  sim?: CampaignSimDetail;
  earlyBird?: EarlyBirdDetail;
  spv?: SpvDetail | null;
  history?: HistoryDetail[];
  exit?: ExitDetail;
  trustCompany?: {
    threshold: string;
    name?: string;
  };
};

type CampaignStatistics = {
  comments: {
    count: number;
    discussionsCount: number;
  };
};

export type CampaignDoubleFundingChild = {
  campaignTitle: string;
  companyFullBusinessName?: string;
  thresholdInvestmentAmount: string;
  type: `${CampaignChildType}`;
};

type CampaignParenthood = {
  campaign: Campaign;
  campaignId: string;
  createdAt: string;
  fatherId: string;
  type: `${CampaignChildType}`;
};

export enum CampaignWidgetType {
  CampaignBoxPrincipal = 'campaign_box_principal',
  CardLabel = 'card_label',
  MetaInfo = 'meta_info',
}

export enum CampaignBoxPrincipalStyle {
  info = 'info',
  alert = 'alert',
}

export type CampaignWidgetBoxPrincipal = {
  type: CampaignWidgetType.CampaignBoxPrincipal;
  data: {
    label: string;
    body: string;
    style: CampaignBoxPrincipalStyle;
  };
};

export type CampaignWidgetCardLabel = {
  type: CampaignWidgetType.CardLabel;
  data: {
    text: string;
  };
};

export type CampaignWidgetMetaInfo = {
  type: CampaignWidgetType.MetaInfo;
  data: {
    title: string;
    description: string;
  };
};

export type CampaignWidget =
  | CampaignWidgetBoxPrincipal
  | CampaignWidgetCardLabel
  | CampaignWidgetMetaInfo;

export type CampaignUserInfo = {
  hasRestrictedInvitation: boolean;
  isEarlyBird: boolean;
  isFollowing: boolean;
};

export type CampaignBank = {
  IBAN: string | null;
  SWIFT: string | null;
  details?: null | {
    bancasella?: {
      businessName: string;
      campaignId: string;
      campaignStatus: SellaCampaignStatus;
      companyId: string;
      createdAt: string;
      ordinaryIBAN: string;
      technicalIBAN: string;
    };
  };
  holderName: string | null;
  isBancaSellaAccount?: boolean;
};

export type Campaign = {
  algType: AlgType;
  accounting?: CampaignAccounting;
  annualDividend?: string;
  bank?: CampaignBank;
  billingParameters?: CampaignBillingParameters;
  campaignMetrics?: CampaignMetrics;
  canEnableTaxBonusCertificate?: boolean;
  children?: CampaignDoubleFundingChild[];
  closeDate: string;
  closeRestrictedPhaseDate?: string;
  comingSoonDate?: string;
  company: Company;
  createdAt?: string;
  details?: CampaignDetail;
  descriptionSections?: DescriptionSection[];
  documents?: Document[];
  earlyBird?: boolean;
  followers?: number;
  goalAmountMin: string;
  goalAmountMax: string;
  goalNominalMin: string;
  goalNominalMax: string;
  id: string;
  info?: CampaignInfo;
  isECSP?: boolean;
  isInProject?: boolean;
  isDoubleFunding?: boolean;
  isMultiStageCampaign?: boolean;
  isPrivate?: boolean;
  isRestricted?: boolean;
  isSPV?: boolean;
  liquidationPreference: boolean;
  minPeriod?: number;
  minPeriodDays?: number;
  multiLangManual?: boolean;
  offlineRaisedAmount?: string;
  openDate: string;
  ownerEditStatus?: OwnerEditStatus;
  parenthood?: CampaignParenthood;
  partners?: Partner[];
  period?: number;
  preMoneyValue: string;
  preShareCapitalValue: string;
  profAlways1stQuote?: boolean;
  quotas?: Quota[];
  refOldId?: number;
  rewards?: Reward[];
  releaseDate?: string;
  restrictedRaisedAmount?: string | null;
  richdata?: {
    businessPartners: { soci: IRichDataSoci[] };
    entityProfileConnections: { esponenti: IRichDataEsponente[] };
  };
  ROIMax?: string;
  ROIYearMax: string;
  shareValue?: string;
  simType?: SimType;
  status: CampaignStatus;
  statistics?: CampaignStatistics;
  systemTitle: string;
  teamMembers?: TeamMember[];
  title: string;
  transferType?: TransferType;
  type: CampaignType;
  updates?: Update[];
  viewMode?: CampaignViewMode;
  enableInvestorTable?: boolean;
  enableTaxBonusCertificate?: boolean;
  widgets?: CampaignWidget[];
  crowdForecast?: string;
  anchorForecast?: string;
  fundForecast?: string;
  professionalCoverageThreshold?: string;
};

export const isTypeREIncome = (type?: `${CampaignType}`): boolean =>
  [CampaignType.realEstateIncome].includes(type as CampaignType);

export const isTypeREDevelopment = (type?: `${CampaignType}`): boolean =>
  [CampaignType.realEstate].includes(type as CampaignType);

export const isTypeRealEstate = (type?: `${CampaignType}`): boolean =>
  isTypeREIncome(type) || isTypeREDevelopment(type);

export type Financials = {
  preMoneyValue: string;
  preShareCapitalValue: string;
  goalMin: string;
  roundValue: string;
  postMoneyValue: string;
  unitSurcharge: string;
  dilutionPerc: string;
  sharePriceMaxErr: string;
  minQuotaValue: string;
};
export interface ISubmitCampaignRound {
  comingSoonDate: Date | string | undefined;
  openDate: Date | string | undefined;
  closeDate: Date | string | undefined;
  goalAmountMin: number;
  goalAmountMax: number;
  goalNominalMin: number;
  goalNominalMax: number;
  preShareCapitalValue: number;
  preMoneyValue: number;
  offlineRaisedAmount: number | undefined;
  shareValue: string;
  professionalCoverageThreshold: string;
  profAlways1stQuote: boolean;
  algType: string;
  details?: {
    spv?: {
      preMoneyValue: string;
      preShareCapitalValue: string;
      dilutionPercMax: string | number | undefined;
      dilutionPercMin: string | number | undefined;
    } | null;
  };
}

export type CampaignForecast = {
  crowdForecast: string;
  anchorForecast: string;
  fundForecast: string;
};

export const getFromCampaignProjectMetrics = (metrics: ProjectMetrics) => {
  if (metrics) {
    if (metrics.algType === 'quota_v3') {
      return {
        // nominal or amount (depends by quota) for calc, percentage or display
        goalMin: metrics.goalAmountMin,
        // for display purposes
        goalAmountMin: metrics.goalAmountMin,
        // nominal or amount (depends by quota) for calc, percentage or display
        goalMax: metrics.goalAmountMax,
        // for display purposes
        goalAmountMax: metrics.goalAmountMax,
        // nominal or amount (depends by quota) for calc, percentage or display
        totalProspect: metrics.totalProspectInvestmentOrderAmount,
        algType: metrics.algType,
      };
    } else {
      return {
        // nominal or amount (depends by quota) for calc, percentage or display
        goalMin: metrics.goalNominalMin,
        // for display purposes
        goalAmountMin: metrics.goalAmountMin.max,
        // only for alg type !== v3, used mainly in tooltip
        goalAmountMinRange: metrics.goalAmountMin,
        // nominal or amount (depends by quota) for calc, percentage or display
        goalMax: metrics.goalNominalMax,
        // for display purposes
        goalAmountMax: metrics.goalAmountMax.max,
        // only for alg type !== v3, used mainly in tooltip
        goalAmountMaxRange: metrics.goalAmountMax,
        // nominal or amount (depends by quota) for calc, percentage or display
        totalProspect: metrics.totalProspectInvestmentOrderNominal,
        algType: metrics.algType,
      };
    }
  }

  return null;
};

export type SecGetCampaignListEarlyBirdType =
  paths['/sec/campaign/{campaignId}/early-bird']['get']['responses']['200']['content']['application/json'];
