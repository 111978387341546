/**
 *
 * EmptyLayout
 *
 */

import Box from 'components/Box';

import { useStyle } from './EmptyLayout.style';
import { ReactNode } from 'react';

interface EmptyLayoutProps {
  children: ReactNode;
}

export function EmptyLayout({ children }: EmptyLayoutProps) {
  const classes = useStyle();

  return <Box className={classes.pageContainer}>{children}</Box>;
}

export default EmptyLayout;
