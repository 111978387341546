/**
 *
 * Button
 *
 */

import PropTypes from 'prop-types';
import { Button as MamaButton } from '@mamacrowd/ui-kit';

export function Button({ text, spinner = false, ...rest }) {
  return (
    <MamaButton loading={spinner} flat {...rest}>
      {text}
    </MamaButton>
  );
}

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  spinner: PropTypes.bool,
};

export default Button;
