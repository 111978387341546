import {
  Theme,
  AlertProps,
  Components,
  ComponentsOverrides,
  Interpolation,
} from '@mui/material';
import { inputBaseClasses } from '@mui/material/InputBase';

const MuiInputBaseRoot = (theme: Theme) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.black,

  // MUI SELECT FOCUS BG
  '& .MuiSelect-select': {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  // MUI INPUT BORDER FIXED WHEN HOVERING
  [`&:hover`]: {
    [`label:not(.${inputBaseClasses.error}):not(.${inputBaseClasses.disabled})`]:
      {
        color: theme.palette.primary.main,
      },
    [`.MuiInput-underline:not(.${inputBaseClasses.disabled}):not(${inputBaseClasses.error}):before`]:
      {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
  },

  // MUI INPUT DISABLED STYLE
  [`.${inputBaseClasses.disabled}`]: {
    backgroundColor: `#F2F2F280`, // opacity 0.8
    color: `#000000B3`,
  },
});

const MuiInputBase = (theme: Theme): Components['MuiInputBase'] => ({
  styleOverrides: {
    root: MuiInputBaseRoot(theme),
  },
});

const ConsoleMuiInputBase = (theme: Theme): Components['MuiInputBase'] => ({
  styleOverrides: {
    root: MuiInputBaseRoot(theme),
    input: {
      fontSize: 14,
      fontWeight: 400,
    },
  },
});

const MuiInput: Components['MuiInput'] = {
  styleOverrides: {
    input: {
      padding: '2px 0px 2px',
    },
  },
};

// to be removed when all buttons are imported from @mamacrowd/ui-kit
const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      fontSize: 14,
      height: 50,
      fontWeight: 600,
    },
  },
};

const MuiFormControl = (theme: Theme): Components['MuiFormControl'] => ({
  styleOverrides: {
    root: {
      [`&:hover`]: {
        [`.MuiFormLabel-root:not(.${inputBaseClasses.error}):not(.${inputBaseClasses.disabled})`]:
          {
            color: theme.palette.primary.main,
          },
        [`.MuiInput-underline:not(.${inputBaseClasses.disabled}):not(${inputBaseClasses.error}):before`]:
          {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          },
      },
    },
  },
});

const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      marginRight: 4,
      padding: 0,
    },
  },
};

const MuiSlider: Components['MuiSlider'] = {
  styleOverrides: {
    markLabel: {
      fontSize: 11,
    },
    markLabelActive: {
      fontSize: 11,
    },
  },
};

const MuiStepButton: Components['MuiStepButton'] = {
  styleOverrides: {
    root: {
      margin: 0,
      marginLeft: -8,
    },
  },
};

const MuiTableCell: Components['MuiTableCell'] = {
  styleOverrides: {
    sizeSmall: {
      padding: 4,
      '&:last-child': {
        paddingRight: 4,
      },
    },
  },
};

const MuiChip = (theme: Theme, isConsole = false): Components['MuiChip'] => {
  const styleOverrides = {
    colorPrimary: {
      backgroundColor: theme.palette.primary.light,
    },
    colorSecondary: {
      backgroundColor: theme.palette.secondary.light,
    },
  };
  return isConsole
    ? { styleOverrides: { ...styleOverrides, root: { height: 24 } } }
    : { styleOverrides };
};

type AlertColorCustom = Interpolation<
  AlertProps &
    Record<string, unknown> & {
      theme: unknown;
    }
>;

type MuiAlertProps = Omit<Components['MuiAlert'], 'styleOverrides'> & {
  styleOverrides: ComponentsOverrides['MuiAlert'] & {
    standardSecondary?: AlertColorCustom;
    standardEarlyBird?: AlertColorCustom;
    outlinedSecondary?: AlertColorCustom;
    outlinedEarlyBird?: AlertColorCustom;
    outlinedRestricted?: AlertColorCustom;
  };
};

const MuiAlert = (theme: Theme): MuiAlertProps => ({
  styleOverrides: {
    standardSecondary: {
      backgroundColor: theme.palette.secondary.light,
    },
    standardEarlyBird: {
      backgroundColor: theme.palette.earlyBird.light,
    },
    outlined: {
      backgroundColor: theme.palette.common.white,
    },
    outlinedSecondary: {
      borderColor: theme.palette.secondary.main,
    },
    outlinedEarlyBird: {
      borderColor: theme.palette.earlyBird.main,
    },
    outlinedRestricted: {
      borderColor: theme.palette.restricted.main,
    },
  },
});

export const components = (theme: Theme): Components => ({
  MuiCheckbox,
  MuiFormControl: MuiFormControl(theme),
  MuiInputBase: MuiInputBase(theme),
  MuiInput,
  MuiSlider,
  MuiStepButton,
  MuiChip: MuiChip(theme),
  MuiAlert: MuiAlert(theme),
  MuiButton,
});

export const consoleComponents = (theme: Theme): Components => ({
  MuiCheckbox,
  MuiFormControl: MuiFormControl(theme),
  MuiInputBase: ConsoleMuiInputBase(theme),
  MuiInput,
  MuiSlider,
  MuiStepButton,
  MuiTableCell,
  MuiChip: MuiChip(theme, true),
  MuiAlert: MuiAlert(theme),
});
